.project-title {
    @include margin-padding(0 0 5px, null);
    a {
        color: $__gray;
    }
}
.team-members {

    @extend %ul_reset;
    @extend %inline-flex;
    img {
        width: $__full__width;
    }
    a {
        border: 2px solid $__white;
        display: $__block;
        height: 30px;
        overflow: $__hidden;
        width: 30px;
        @include rounded(100%);
    }
    .all-users {
        background-color: $__fire__red;
        color: $__white;
        font-size: $__font__size__10;
        font-weight: $__bold;
        line-height: 30px;
        text-align: $__center;
    }
    
}
.profile-action {
    text-align: $__right;
    @include position($__absolute,10px,5px,null,null);
}
.project-members {
    a {
        margin-left: -10px;
        margin-right: 0;
        position: $__relative;
        &:first-child {
            margin-left: 0;
        }  
    }
    .team-members {
        li {
            a {
                margin-left: -10px;
                display: $__inline__flex;
                align-items: $__center;
                justify-content: $__center;
            }
            &:first-child {
                a {
                    margin-left: 0;
                }
            }
        }
    }
    &.task-followers {
        :nth-child(2) {
            margin-left: 0;
        }
    }
}
.pro-team-members {
    h4 {
        margin-bottom: 0;
    }
}
table {
    .team-members {
        li {
            a {
                margin-left: -10px;
                display: $__inline__flex;
                align-items: $__center;
                justify-content: $__center;
            }
            &:first-child {
                a {
                    margin-left: 0;
                }
            }
        }
    }
}
.modal .modal-body .input-block {
    &.row {
        margin-left: auto;
    }
}
.header {
    .has-arrow {
        &.flag-nav {
            .dropdown-toggle {
                &:after {
                    position: relative;
                    top: -2px;
                }
            }
        }
    } 
}
.dropdown {
    .btn-rounded {
        display: $__inline__flex;
        align-items: $__center;
        gap: 5px;
    }
}
.table-avatar {
    a {
        &.avatar {
            line-height: normal;
        }
    } 
}
.progress-bar {
    &.w-40 {
        width: 40%;
    } 
    &.w-65 {
        width: 65%;
    }
    &.w-15 {
        width: 15%;
    }
    &.w-88 {
        width: 88%;
    }
    &.w-70 {
        width: 70%;
    }
    &.w-31 {
        width: 31%;
    }
    &.w-62 {
        width: 62%;
    }
    &.w-22 {
        width: 22%;
    }
    &.w-24 {
        width: 24%;
    }
    &.w-30 {
        width: 30%;
    }
    &.w-26 {
        width: 26%;
    }
    &.w-10 {
        width: 10%;
    }
    &.w-21 {
        width: 21%;
    }
    &.w-20 {
        width: 20%;
    }
}
th {
    &.width-pixel {
        width: 40px;
    }
}
.btn-close:focus {
    box-shadow: none;
}
.progress {
    &.height-five {
        height: 5px;
    }
}
textarea {
    &.form-control {
        height: $__auto;
    }
}
td {
    &.tdata-width {
        width: 230px;
    }
}
th {
    &.width-thirty {
        width: 30px;
    }
}
.badge.min-w-90 {
    min-width: 90px;
}
td.w-50pixel {
    width: 50px;
}
td.w-300pixel {
    width: 300px;
}
.progress.height-5 {
    height: 5px;
}
.progress-bar.w-73 {
    width: 73%;
}
.progress-bar.height-10 {
    height: 10px;
}
.form-control.width-160 {
    width: 160px;
}
thead .bg-thead {
    background:#f2f2f2;
}
th.width-250 {
    width: 250px;
}
th.width-70 {
    width: 70px;
}
td.width-55 {
    min-width:55px;
    max-width:55px;
    width:55px;
}
a.green-border {
    border:2px dashed #1eb53a
}
th.width-64 {
    width: 64px;
}