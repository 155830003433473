.progress-container {
    margin-bottom: 20px;
}

.progress {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    position: relative;
}

.progress .progress-bar {
    background-color: #007bff;
    height: 100%;
    border-radius: 5px;
}

/* .step-indicators {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.step-indicator {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.step-indicator.active {
    background-color: #007bff;
    color: #fff;
}

.step-indicator .step-number {
    font-size: 12px;
    font-weight: bold;
} */

.step-indicators {
    display: flex;
    justify-content: space-between;
}

.step-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.step-number {
    font-size: 12px;
}

.step-heading {
    font-size: 12px;
}

.step-indicator.active .step-number {
    color: #007bff;
}

.step-indicator.active .step-heading {
    font-weight: bold;
}
.step-headings {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.step-heading {
    font-size: 12px;
}

.step-heading.active {
    font-weight: bold;
}
