body{
	font-family: $__body__font__family;
	font-variant: unset;    
	font-feature-settings: normal;
	font-size:$__body__font__size;
	color:$__secondarycolor;
	background-color: $__mix__gray;
	overflow-x: $__hidden;
}
.container{
	width:$__full__width;
	max-width:$__container__width;
	padding-left:$__grid__padding__left;
	padding-right:$__grid__padding__right;
	margin:$__margin__auto;
}
img {
	max-width:$__full__width;
	height: $__auto;
	vertical-align: $__middle;
}
a{
	text-decoration: $__none;
    outline: $__none;
	color:$__sky__blue;
	cursor: $__pointer;
	@include transition(all 0.2s ease);
	&:hover{
		@include transition(all 0.2s ease);
	}
	&:focus {
		outline: 0;
	}
}
p{
	margin-bottom:1rem;
}
strong{
	font-weight: $__bold;
}

.react-datepicker-wrapper {
	width: 100%;
}
.rsw-editor {
	height: 184px;
}
.fc {
	.fc-toolbar.fc-header-toolbar {
		margin-bottom: 1em !important;
	}
}
$color_1: rgba(0, 0, 0, 0.85);

.ant-picker-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: $color_1;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1150;
}
span.ant-table-column-title {
	white-space: nowrap;
}
.ant-table-tbody > tr > td {
	white-space: nowrap;
}

label.custom-control-label.set-marg {
	padding-left: 4px;
}
